import React from "react";
import { Divider, Stack, Switch, TextField } from "@mui/material";
import { Controller, useFieldArray, useWatch } from "react-hook-form";
import ButtonBlue from "../../../shared/general/ButtonBlue";

const EstimateLevel = ({ control }) => {
  const { fields, append } = useFieldArray({ control, name: "level" });
  const level = useWatch({ control, name: "level" })

  return (
    <>
      <Stack direction={"row"} flexWrap={"wrap"} gap={1}>
        {fields.map((item, index) => (
          <div key={item.id} style={{ display: "flex" }}>
            <Controller
              control={control}
              name={`level.${index}.name`}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error && fieldState.error.message}
                  inputProps={{ readOnly: Boolean(level[index] && level[index].idEstimateLevel ? true : false) }}
                />
              )}
            />
            <Controller 
              control={control}
              name={`level.${index}.active`}
              render={({ field }) => (
                <Switch {...field} checked={field.value} />
              )}
            />
            <Divider
              flexItem
              orientation="vertical"
              sx={{ borderColor: "#9e9e9e66", marginLeft: "8px" }}
            />
          </div>
        ))}
      </Stack>
      <ButtonBlue
        type="button"
        startIcon={<i className="fa-regular fa-plus"></i>}
        onClick={() => append({ name: "", active: true })}
      >
        เพิ่มระดับ
      </ButtonBlue>
      <ButtonBlue type="submit" />
    </>
  );
};

export default EstimateLevel;
