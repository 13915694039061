import React from "react";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";

export default function SnackbarResponse({ handleClose, configSnackbar }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={configSnackbar.open}
      onClose={handleClose}
      autoHideDuration={6000}
    >
      <Alert onClose={handleClose} severity={configSnackbar.color} sx={{ width: '100%' }}>
        {configSnackbar.message}
      </Alert>
    </Snackbar>
  );
}
