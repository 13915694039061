import { httpClient } from "./httpClient";

const getAllOrgChart = () => {
  return httpClient.get("orgCharts");
};

const getOrganizationChart = () => {
  return httpClient.get("organization-chart"); 
}

const addOrganizationNode = (formData) => {
  return httpClient.post("organization-chart", formData); 
}

export default {
  getAllOrgChart,
  getOrganizationChart,
  addOrganizationNode,
};
