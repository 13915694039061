import { 
  ORGCHART_FETCHING,
  ORGCHART_FAILED, 
  ORGCHART_SUCCESS,
  ORGANIZATION_CHART_FETCHING,
  ORGANIZATION_CHART_FAILED, 
  ORGANIZATION_CHART_SUCCESS,
 } from "./types";

import OrgChartService from "../services/orgChart.service";

export const getAllOrgChart = () => async (dispatch) => {
  try {
    const res = await OrgChartService.getAllOrgChart();
    if (res) {
      dispatch({
        type: ORGCHART_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ORGCHART_FAILED,
    });
    console.log(err);
  }
};

export const getOrganizationChart = () => async (dispatch) => {
  try {
    dispatch({
      type: ORGANIZATION_CHART_FETCHING,
    });
    const res = await OrgChartService.getOrganizationChart();
    if (res) {
      dispatch({
        type: ORGANIZATION_CHART_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ORGANIZATION_CHART_FAILED,
    });
    console.log(err);
  }
};

export const addOrganizationNode = (formData) => async () => {
  try {
    const res = await OrgChartService.addOrganizationNode(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    // console.log(error.response);
    if (error.response) {
      return error.response;
    }
  }
};
