import React, { useEffect, useState } from "react";
import { Box, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { PDFDocument } from "pdf-lib";
import { getOrganizationChart } from "../../../../actions/orgChart";
import OrgChartComponent from "./OrgChart";
import DrawerEdit from "./DrawerEdit";
import { getOrganizationStructure } from "../../../../actions/organizationStructure";
import { clearEmployeeNetwork, getAllEmployees, getEmployeeNetwork } from "../../../../actions/employee";
import Profile from "./DrawerProfile";

const StyledRoot = styled(Box)({
  "& .diagram-component": {
    width: "100vw",
    height: "calc(100vh - 80px)",
    backgroundColor: "white",
  },
  "& .react-transform-wrapper": {
    width: "100vw",
    height: "calc(100vh - 80px)",
    cursor: "grab"
  },
})

const OrganizationChart = () => {

  const dispatch = useDispatch();
  const { result: OrganizationStructureData } = useSelector(state => state.organizationStructure);
  const { result: EmployeeData } = useSelector(state => state.employees);

  const [dataList, setDataList] = useState([]);

  const [drawerEditConfig, setDrawerEditConfig] = useState({
    isOpen: false,
  })
  const [open, setOpen] = useState(false);

  const onNodeClick = (node) => {
    dataList.map((item) => {
      if (item.id == node) {
        // console.log(item)
        if (item.nodeType === "EMPLOYEE" && item.employeeList[0]) {
          dispatch(getEmployeeNetwork(item.employeeList[0].idEmployees))
          setOpen(true)
          // console.log(item.employeeList[0].idEmployees)
        } else if (item.mainPosition && item.mainPosition.employee) {
          dispatch(getEmployeeNetwork(item.mainPosition.employee.idEmployees))
          setOpen(true)
          // console.log(item.mainPosition.employee.idEmployees)
        }
      }
    })
  }

  useEffect(() => {
    dispatch(getOrganizationChart())
    dispatch(getOrganizationStructure())
    dispatch(getAllEmployees());
  }, [])

  useEffect(async () => {
    await getOrganizationStructureList()
  }, [OrganizationStructureData, EmployeeData])

  const getOrganizationStructureList = async () => {
    if (OrganizationStructureData && EmployeeData) {
      const data = [];
      const totalEmployees = {};
      const pictureEmployeeMap = {};

      const EmployeePromise = await Promise.all(EmployeeData.map(async item => {
        return await new Promise(async (resolve, reject) => {
          const response = await fetch(item.imageProfile, { mode: 'no-cors' })
          const blob = await response.blob();
          const buf = await blob.arrayBuffer();

          resolve({
            ...item,
            blob: blob ? URL.createObjectURL(new Blob([buf], { type: "image/png" })) : null
          })
        })
      }))

      EmployeePromise.map(async item => {
        pictureEmployeeMap[item.idEmployees] = {
          url: item.imageProfile,
          blob: item.blob ? item.blob : null
        }
      })

      const tempData = [
        ...OrganizationStructureData.ceos,
        ...OrganizationStructureData.businessUnits,
        ...OrganizationStructureData.divisions,
        ...OrganizationStructureData.departments,
        ...OrganizationStructureData.sections,
        ...OrganizationStructureData.positions,
      ]

      tempData.map(item => {
        data.push(
          {
            ...item,
            id: item.code,
            parentId: item.parent ? item.parent.parentCode : null
          }
        )
      })

      const employeePositionMap = {};

      OrganizationStructureData.employees.map(item => {
        if (!totalEmployees["CEO-00001"]) {
          totalEmployees["CEO-00001"] = 0;
        }
        totalEmployees["CEO-00001"] += 1
        if (item.parent.businessUnitCode) {
          if (!totalEmployees[item.parent.businessUnitCode]) {
            totalEmployees[item.parent.businessUnitCode] = 0;
          }
          totalEmployees[item.parent.businessUnitCode] += 1
        }
        if (item.parent.divisionCode) {
          if (!totalEmployees[item.parent.divisionCode]) {
            totalEmployees[item.parent.divisionCode] = 0;
          }
          totalEmployees[item.parent.divisionCode] += 1
        }
        if (item.parent.departmentCode) {
          if (!totalEmployees[item.parent.departmentCode]) {
            totalEmployees[item.parent.departmentCode] = 0;
          }
          totalEmployees[item.parent.departmentCode] += 1
        }
        if (item.parent.sectionCode) {
          if (!totalEmployees[item.parent.sectionCode]) {
            totalEmployees[item.parent.sectionCode] = 0;
          }
          totalEmployees[item.parent.sectionCode] += 1
        }

        if (!employeePositionMap[item.parent.parentCode]) {
          employeePositionMap[item.parent.parentCode] = [];
        }
        employeePositionMap[item.parent.parentCode].push({
          ...item,
          imageProfile: pictureEmployeeMap[item.idEmployees] ? pictureEmployeeMap[item.idEmployees] : null,
        })
      })

      Object.keys(employeePositionMap).map((key, index) => {
        // data.push(
        //   {
        //     id: `EMP-GROUP${key}`,
        //     parentId: key,
        //     employeeList: employeePositionMap[key],
        //     nodeType: "EMPLOYEE",
        //   }
        // )
        employeePositionMap[key].map((item, i) => {
          data.push({
            id: `EMP-GROUP${item.idEmployees}`,
            parentId: key,
            employeeList: [item],
            nodeType: "EMPLOYEE",
          })
        })
      })

      data.map(item => {
        item.totalEmployees = 0;
        item.totalEmployees = totalEmployees[item.id] ? totalEmployees[item.id] : 0
      })
      setDataList(data);
      return data;
    } else {
      return [];
    }
  }

  const downloadPdf = async (chart) => {
    chart.exportImg({
      save: false,
      onLoad: async (base64) => {
        const pdfDoc = await PDFDocument.create();
        const page = pdfDoc.addPage([595 / 3, ((chart.height / chart.width) * 595) / 3]);

        const imageBytes = Uint8Array.from(atob(base64.split(',')[1]), c => c.charCodeAt(0));
        const image = await pdfDoc.embedPng(imageBytes);

        page.drawImage(image, {
          x: 5,
          y: page.getHeight() - 5 - ((chart.height / chart.width) * 595) / 3,
          width: 595 / 3,
          height: ((chart.height / chart.width) * 595) / 3,
        });

        const pdfBytes = await pdfDoc.save();

        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'organization-chart.pdf';
        link.click();
      },
    });
  }

  return (
    <StyledRoot className="page">
      {dataList.length > 0 && <OrgChartComponent
        data={dataList}
        onNodeClick={onNodeClick}
        onDownload={downloadPdf}
      />}
      <DrawerEdit
        config={drawerEditConfig}
        onClose={() => {
          setDrawerEditConfig(p => ({
            ...p,
            isOpen: false
          }))
        }}
      />
      <Profile
        open={open}
        handleClose={() => {
          setOpen(false)
          dispatch(clearEmployeeNetwork());
        }}
      />
    </StyledRoot>
  )
}

export default OrganizationChart;