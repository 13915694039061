import React from "react";
import { styled } from "@mui/material/styles";
import {
    Typography,
    TextField,
    Popper,
    Button,
    Box,
    FormControl,
    InputAdornment,
    Checkbox,
    Drawer,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import LoadingButton from '@mui/lab/LoadingButton';
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import ButtonBlue from "../shared/general/ButtonBlue";

const StyledBoxSearch = styled(Box)({
    marginTop: 20,
    marginBottom: 18,
    "& .label": {
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 8,
    },
});

const StyledAutocomplete = styled(Autocomplete)({
    width: "100%",
    border: 0,
    "& .MuiFilledInput-root": {
        backgroundColor: "#919eab14",
        height: 56,
        padding: "0px 12px",
        borderRadius: 8,
        "&.Mui-focused": {
            backgroundColor: "#919eab14",
        },
        "& .MuiInputAdornment-root": {
            width: 32,
            marginTop: "0!important",
            fontSize: 24,
            color: "#919EAB",
            "& i": {
                marginRight: 8,
            },
        },
        "& .MuiAutocomplete-endAdornment": {
            "& .MuiButtonBase-root": {
                fontSize: 14,
                width: 22,
                height: 22,
            },
        },
        "&:hover": {
            backgroundColor: "#919eab29",
            "&:before": {
                border: "none !important",
            },
        },
        "&::after": {
            border: "none",
        },
        "&::before": {
            border: "none",
        },
    },
});

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        [`& .${autocompleteClasses.option}`]: {
            "&:hover": {
                backgroundColor: "#f6f7f8",
                margin: "0 8px",
                borderRadius: 8,
                paddingLeft: 8,
            },
        },
    },
});

const StyledButtonCheck = styled(LoadingButton)({
    "&.check-in-btn": {
        color: "#fff",
        width: "100%",
        height: "50px",
        backgroundColor: "#66bb6a",
        border: "1px solid #66bb6a",
        "&:hover": {
            borderColor: "#4caf50",
            backgroundColor: "#4caf50",
        },
    },
    "&.check-out-btn": {
        color: "#fff",
        width: "100%",
        height: "50px",
        backgroundColor: "#ef5350",
        border: "1px solid #ef5350",
        "&:hover": {
            borderColor: "#e53935",
            backgroundColor: "#e53935",
        },
    },
    "&.loadMap": {
        width: "100%",
        height: "50px",
    }
});

const SelectedGroupLocations = (props) => {
    const {
        lineUserProfile,
        mode,
        handleCancel,
        handleChangeClickCheck,
        setLocations,
        locations,
        loadMap,
    } = props;

    const { result: timeline } = useSelector((state) => state.timeline);

    const handleClick = () => {
        if (locations === null && timeline.type !== "point") {
            alert("กรุณาเลือกสถานที่");
        } else {
            handleChangeClickCheck();
        }
    };

    return (
        <div>
            {lineUserProfile && (
                <div>
                    {timeline.type !== "point" && <StyledBoxSearch>
                        <Typography variant="subtitle1" color="text.third">
                            สถานที่
                        </Typography>
                        <StyledAutocomplete
                            options={lineUserProfile.groupGpsLocations}
                            onChange={(event, newValue) => {
                                setLocations(newValue.locations);
                            }}
                            popupIcon={<i class="fa-light fa-chevron-down"></i>}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="filled"
                                    placeholder={"เลือกสถานที่"}
                                />
                            )}
                            PopperComponent={StyledPopper}
                            noOptionsText={"ไม่พบข้อมูล"}
                        />
                    </StyledBoxSearch>}
                    <div>
                        {mode === "in" ? (
                            <StyledButtonCheck
                                variant="outlined"
                                className={`${loadMap ? "loadMap" : "check-in-btn"}`}
                                startIcon={<LoginIcon />}
                                onClick={handleClick}
                                loading={loadMap}
                                loadingPosition="start"
                            >
                                {`${loadMap ? "ดึงตำแหน่งปัจจุบัน" : "ยืนยันลงเวลาเข้างาน"}`}
                            </StyledButtonCheck>
                        ) : (
                            <StyledButtonCheck
                                variant="outlined"
                                className={`${loadMap ? "loadMap" : "check-out-btn"}`}
                                startIcon={<LogoutIcon />}
                                onClick={handleClick}
                                loading={loadMap}
                                loadingPosition="start"
                            >
                                {`${loadMap ? "ดึงตำแหน่งปัจจุบัน" : "ยืนยันลงเวลาออกงาน"}`}
                            </StyledButtonCheck>
                        )}
                    </div>
                    <div
                        style={{ marginTop: 16, display: "flex", justifyContent: "center" }}
                    >
                        <ButtonBlue onClick={handleCancel}>ยกเลิก</ButtonBlue>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SelectedGroupLocations;
